<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import Alert from "./AlertComp.vue";
import Spinner from "./SpinnerComp.vue";
import TextButton from "./TextButton.vue";
import FormattedError from "./formattedError.vue";
import type { ErrorResponse } from "@/types";
const props = defineProps<{
  title: string;
  modelValue: boolean;
  disabled?: boolean;
  error: Error & ErrorResponse | null;
  errorMessage?: string | null;
  noMaxWidth?: boolean;
  showCloseButton?: boolean;
  cardClass?: string;
  cardId?: string;
  contentClass?: string;
  errorEntity?: string;
  timeoutAction?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", payload: boolean): void;
}>();

const closeModal = () => {
  emit("update:modelValue", false);
};
</script>

<template>
  <TransitionRoot as="template" :show="props.modelValue">
    <Dialog
      as="div"
      class="relative z-20"
      @close="() => (!disabled ? $emit('update:modelValue', false) : null)"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-secondary bg-opacity-50 transition-opacity"
        ></div>
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform rounded-lg bg-white pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:py-6 sm:mx-4"
              :class="[noMaxWidth ? '' : 'sm:w-full sm:max-w-sm', cardClass]"
              :id="cardId"
            >
              <!--overflow-hidden-->
              <TransitionRoot
                :show="disabled"
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="disabled-overlay absolute flex rounded-md inset-0 bg-white bg-opacity-75 transition-opacity z-10"
                >
                  <Spinner class="m-auto" :size="36" />
                </div>
              </TransitionRoot>
              <div>
                <div class="flex gap-4 justify-between px-4 sm:px-6">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-secondary"
                    >{{ props.title }}</DialogTitle
                  >
                  <template v-if="showCloseButton">
                    <TextButton @click="closeModal()" class="close-modal-button">
                      <span class="icon-close-sm text-[16px] block"></span>
                    </TextButton>
                  </template>
                </div>
                <div class="mt-4 pt-1 px-4 sm:px-6 overflow-x-auto pb-1 -mb-1" :class="contentClass" style="max-height: calc(100vh - 250px);">
                  <Alert
                    class="modal-error-alert mx-auto mb-4"
                    :show="error !== null"
                    type="error"
                    >
                      <FormattedError :error="error" :entity="errorEntity" :action="timeoutAction ? timeoutAction : null">{{ errorMessage }}</FormattedError>
                    </Alert
                  >
                  <slot></slot>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
./SpinnerComp.vue./AlertComp.vue