<script setup lang="ts">
import Card from "./CardComp.vue";
import Alert from "./AlertComp.vue";
import Overlay from "./OverlayComp.vue";
import { ref, onMounted } from "vue";
import FormattedError from "./formattedError.vue";
import type { ErrorResponse } from "@/types";

defineProps<{
  disabled?: boolean;
  error: Error & ErrorResponse | null;
  cardClass?: string;
  overlayClass?: string;
  disabledSpinner?: boolean;
}>();

const top = ref<number | undefined>(0);

onMounted(() => {
  let el = document.getElementById("main");
  el?.addEventListener("scroll", () => {
    let scr = el?.scrollTop ? el.scrollTop : 0;
    let off = el?.offsetTop ? el.offsetTop : 0;
    top.value = scr + off;
  });
});

const header = ref<HTMLElement | null>(null);
</script>

<template>
  <div class="table-wrap relative">
    <Overlay :show="disabled" class="rounded-md" :class="overlayClass" :spinner="disabledSpinner">
    </Overlay>
    <Card nopadding class="relative overflow-x-auto" :class="cardClass">
      <table class="min-w-full divide-y divide-gray-dark">
        <thead ref="header">
          <tr>
            <slot name="headers"></slot>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <div v-if="error !== null" class="table-row">
            <td colspan="100%">
              <Alert
                class="table-error mx-auto mb-4 mt-4"
                :show="error !== null"
                type="error"
                >
                  <FormattedError :error="error"></FormattedError>
                </Alert
              >
            </td>
          </div>
          <slot></slot>
        </tbody>
      </table>
    </Card>
  </div>
</template>

<style>
.fixed-header {
  transition: top 0.15s ease-out;
}
.fixed-header th {
  display: inline-block;
}
</style>./SpinnerComp.vue./OverlayComp.vue./CardComp.vue./AlertComp.vue
