import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { Document, SearchResponse } from '@/types'
import axios from 'axios'

export const useDocumentsStore = defineStore('documents', () => {
  const documents = ref<Document[]>([]);
  const results = ref<Document[]>([]);
  const paginationObjs = ref<any[]>([]);
  const advancedResults = ref<SearchResponse | null>(null);
  const fakturaer = ref<SearchResponse | null>(null);
  const fakturaspecifikationer = ref<SearchResponse | null>(null);
  const fakturabilag = ref<SearchResponse | null>(null);
  const selectedDocuments = ref<string[]>([]);
  const highlightedDocument = ref<number | null>(null);

  const addDocument = (document: Document) => {
    if (documents.value.find(doc => doc.id === document.id)) {
      documents.value[documents.value.findIndex((i) => i.id === document.id)] = document;
    } else {
      documents.value.push(document)
    }
  }

  const getDocumentFileFromApi = (id: string) => {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/documents/${id}`).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
    
  }

  const getDocumentFromStore = (id: string) => {
    return new Promise((resolve, reject) => {
      if (documents.value.find(doc => doc.id === id)) {
        resolve(documents.value.find(doc => doc.id === id))
      } else {
        reject()
      }
    })
  }

  const toggleDocumentSelected = (id: string) => {
    const i = selectedDocuments.value.indexOf(id)
    if (i === -1) {
      selectedDocuments.value.push(id)
    } else {
      selectedDocuments.value.splice(i, 1);
    }
  }

  return { documents, results, paginationObjs, advancedResults, fakturaer, fakturaspecifikationer, fakturabilag, selectedDocuments, highlightedDocument, addDocument, getDocumentFileFromApi, getDocumentFromStore, toggleDocumentSelected }
})
