<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  page: number;
  perPage: number;
  total: number;
  tabindex?: string;
  textBottom?: boolean;
}>();

const totalPages = computed(() => {
  return Math.ceil(props.total / props.perPage);
});

const pageButtons = computed(() => {
  let allPages = [...Array(totalPages.value).keys()];
  let selectedPages = [] as { number: number; type: string }[];
  for (let page of allPages) {
    if (
      page === 0 ||
      page === 1 ||
      props.page === page + 1 ||
      props.page - 1 === page + 1 ||
      props.page + 1 === page + 1 ||
      totalPages.value === page + 1 ||
      totalPages.value - 1 === page + 1
    ) {
      selectedPages.push({
        number: page + 1,
        type: "page",
      });
    }
  }
  let indexes = [] as number[];
  selectedPages.forEach(function (value, i) {
    if (
      selectedPages[i + 1] &&
      selectedPages[i + 1].number !== value.number + 1
    ) {
      indexes.push(i + 1);
    }
  });
  let i = 0;
  for (let index of indexes) {
    selectedPages.splice(index + i, 0, { number: 0, type: "pause" });
    i++;
  }
  return selectedPages;
});

const emit = defineEmits<{
  (e: "changePage", payload: number): void;
}>();

const changePage = (page: number) => {
  emit("changePage", page);
};

const nextPage = () => {
  if (props.page !== totalPages.value) emit("changePage", props.page + 1);
};

const prevPage = () => {
  if (props.page !== 1) emit("changePage", props.page - 1);
};
</script>

<template>
  <div class="pagination flex items-center justify-between">
    <div class="flex flex-1 justify-between sm:hidden">
      <a
        @click.prevent="prevPage()"
        :tabindex="tabindex"
        href=""
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-secondary hover:bg-gray-50 focus-visible:ring-primary focus:ring-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-opacity-30"
        >Forrige</a
      >
      <a
        @click.prevent="nextPage()"
        :tabindex="tabindex"
        href=""
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-secondary hover:bg-gray-50 focus-visible:ring-primary focus:ring-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-opacity-30"
        >Næste</a
      >
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:justify-between" :class="textBottom ? 'sm:items-end' : 'sm:items-start'">
      <div>
        <p class="text-sm text-gray-darker" v-if="total !== 0">
          Viser
          <span class="font-medium">{{
            props.perPage * (props.page - 1) + 1
          }}</span>
          til
          <span class="font-medium">{{
            props.total < props.perPage
              ? props.total
              : props.perPage * props.page > props.total
              ? props.total
              : props.perPage * props.page
          }}</span>
          af
          <span class="font-medium">{{ props.total }}</span>
          resultater
        </p>
      </div>
      <div v-if="totalPages > 1">
        <nav
          class="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <a
            @click.prevent="prevPage()"
            :tabindex="tabindex"
            href=""
            class="relative inline-flex items-center rounded-l-md border border-gray-dark bg-white px-2 py-2 text-sm font-medium text-secondary hover:bg-gray focus-visible:ring-primary focus:ring-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-opacity-30"
          >
            <span class="sr-only">Forrige</span>
            <span class="icon-chevron-left-sm w-5 text-center" aria-hidden="true"></span>
          </a>
          <template v-for="(page, index) in pageButtons" :key="index">
            <a
              v-if="page.type === 'page'"
              href=""
              @click.prevent="changePage(page.number)"
              :tabindex="tabindex"
              :class="
                page.number === props.page
                  ? 'z-10 border-primary/25 bg-primary-ultralight/25 text-primary'
                  : 'z-0 border-gray-dark bg-white text-secondary hover:bg-gray'
              "
              class="relative inline-flex items-center border px-4 py-2 text-sm font-medium focus-visible:ring-primary focus:ring-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-opacity-30"
              >{{ page.number }}</a
            >
            <span
              v-else
              class="relative inline-flex items-center border border-gray-dark bg-white px-4 py-2 text-sm font-medium text-gray-darker"
              >...</span
            >
          </template>
          <a
            @click.prevent="nextPage()"
            :tabindex="tabindex"
            href=""
            class="relative inline-flex items-center rounded-r-md border border-gray-dark bg-white px-2 py-2 text-sm font-medium text-secondary hover:bg-gray focus-visible:ring-primary focus:ring-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-opacity-30"
          >
            <span class="sr-only">Næste</span>
            <span class="icon-chevron-right-sm w-5 text-center" aria-hidden="true"></span>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>
