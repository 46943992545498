import { createRouter, createWebHistory } from 'vue-router';
import SearchView from '../views/SearchView.vue';
import MainView from '../views/MainView.vue';
import { registerGuard } from "./Guard";
import { useUsersStore } from "@/stores/user";
import { msalInstance, loginRequest } from "../authConfig";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "main",
      component: MainView,
      children: [
        {
          path: "/",
          name: "home",
          component: SearchView,
          meta: {
            requiresAuth: true,
            permission: 'document:view'
          }
        },
        {
          path: "/advanced-search",
          name: "advanced search",
          component: () => import('../views/AdvancedSearchView.vue'),
          meta: {
            requiresAuth: true,
            permission: 'document:view'
          }
        },
        {
          path: "/document/:id",
          name: "document",
          component: () => import('../views/DocumentView.vue'),
          meta: {
            requiresAuth: true,
            permission: 'document:view'
          }
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import('../views/ProfileView.vue'),
          meta: {
            requiresAuth: true,
            permission: 'principal:view'
          }
        },
        {
          path: "/admin",
          name: "admin",
          component: () => import('../views/AdminView.vue'),
          meta: {
            requiresAuth: true,
            permission: 'admin:view'
          },
          children: [
            {
              path: "email",
              name: "admin/email",
              component: () => import('../views/EmailTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['admin:view', 'mail:view']
              }
            },
            {
              path: "archives",
              name: "admin/archives",
              component: () => import('../views/ArchivesTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['archive:view', 'admin:view']
              }
            },
            {
              path: "archives/:id",
              name: "admin/archive",
              component: () => import('../views/ArchivesTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['archive:view', 'admin:view']
              }
            },
            {
              path: "document-types",
              name: "admin/document-types",
              component: () => import('../views/DocumentTypesTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['document-type:view', 'admin:view']
              }
            },
            {
              path: "document-types/:id",
              name: "admin/document-type",
              component: () => import('../views/DocumentTypesTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['document-type:view', 'admin:view']
              }
            },
            {
              path: "field-templates",
              name: "admin/field-templates",
              component: () => import('../views/FieldTemplatesTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['field:view', 'admin:view']
              }
            },
            {
              path: "field-templates/:id",
              name: "admin/field-template",
              component: () => import('../views/FieldTemplatesTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['field:view', 'admin:view']
              }
            },
            {
              path: "users",
              name: "admin/users",
              component: () => import('../views/UsersTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['principal:view', 'admin:view']
              },
            },
            {
              path: "logs",
              name: "admin/logs",
              component: () => import('../views/LogsTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['audit-logging:view', 'admin:view']
              }
            },
            {
              path: "logs/audit",
              name: "admin/logs/audit",
              component: () => import('../views/LogsTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['audit-logging:view', 'admin:view']
              }
            },
            {
              path: "logs/import",
              name: "admin/logs/import",
              component: () => import('../views/LogsTab.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['archiver-logging:view', 'admin:view']
              }
            },
            {
              path: "delete-data",
              name: "admin/delete-data",
              component: () => import('../views/DeleteDataView.vue'),
              meta: {
                requiresAuth: true,
                permissions: ['document-deletion-jobs:view', 'admin:view']
              },
            },
          ]
        }
      ]
    },
    {
      path: "/sign-in",
      name: "sign-in",
      component: () => import('../views/LoginView.vue')
    },
    {
      path: "/failed",
      name: "failed",
      component: () => import('../views/FailedView.vue')
    }
  ]
})

registerGuard(router);

router.beforeEach(async (to, from) => {
  const userStore = useUsersStore();
  if (!userStore.permissions.length) {
    try {
      const response = await msalInstance.acquireTokenSilent({
        ...loginRequest
      }).catch(async (e) => {
        throw e;
      });
      await userStore.getPermissions(response.accessToken).catch((error: any) => {
        console.debug(error)
      })
    }
    catch (error) {
      console.debug(error)
      if (to.name !== 'sign-in') return '/sign-in'
    }
  }
  if (to.meta.permission && !userStore.checkPermission(to.meta.permission as string)) {
    if (to.name === 'home') {
      return '/failed'
    }
    return from.path
  }
  if (to.meta.permissions) {
    for (const permission of to.meta.permissions as string[]) {
      if (!userStore.checkPermission(permission as string)) {
        if (to.name === 'home') {
          return '/failed'
        }
        return from.path
      }
    }
  }
})

export default router;
