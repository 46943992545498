<script lang="ts" setup>
import TextButton from './TextButton.vue';
defineProps<{
  sidebarOpen: boolean;
  disabled?: boolean;
}>();
const emit = defineEmits<{
  (e: "toggleSidebar", payload: boolean): void;
}>();
const toggleSidebar = (value: boolean) => {
  emit("toggleSidebar", value);
};
</script>

<template>
  <div
    class="sidebar fixed bg-white transition-all w-[300px] left-0 bottom-0 top-[64px] z-20 border-r pt-11"
    :class="sidebarOpen ? 'left-0' : 'left-[-300px]'"
  >
    <TextButton @click="() => toggleSidebar(false)" class="close-sidebar-button absolute right-3 top-3" tabindex="-1" color="secondary" :disabled="disabled">
      <span class="icon-close text-[24px]"></span>
    </TextButton>
    <Transition name="fade">
      <div class="overflow-auto h-full px-4 pb-4" v-show="!disabled">
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>