import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useTimeoutsStore = defineStore('timeout', () => {
  const timeouts = ref<{id: string; message?: string; type?: string; show?: boolean; timeout: any, route: string; local?: boolean}[]>([]);
  const timeoutMessage = ref('Der kom ikke et svar fra serveren.\nPrøv venligst at genindlæse siden, eller kontakt din administrator, hvis problemet fortsætter.')
  const errorHeaders = {
    'x-test-mode-delay': 2,
    'x-test-mode-status': 404,
    // 'x-test-mode-error-code': 2
  }

  const getStandardMessage = (action: string) => {
    return `Det tager længere tid, end forventet, at ${action}.\nGiv os et øjeblik, eller prøv at genindlæse siden, hvis problemet fortsætter.`
  }

  const getSecondMessage = (action: string) => {
    return `Der er måske problemer med, at ${action}.\nPrøv at genindlæse siden, eller kontakt din administrator, hvis problemet fortsætter.`
  }

  const getTimeoutErrorMessage = (action: string | undefined = undefined) => {
    if (action) {
      return `Kunne ikke ${action}. ${timeoutMessage.value}`
    }

    return timeoutMessage.value
  }

  const generateId = () => {
    return Math.random().toString(16).slice(2)
  }

  const addTimeout = (id: string, message: string, type: string = 'info', amount: number = 2000, idToRemove: string | undefined = undefined, route: string, local: boolean) => {
    const t = {
      id,
      type,
      route,
      message,
      show: false,
      timeout: null,
      local
    } as {id: string; timeout: any; type: string; route: string;}
    
    t.timeout = setTimeout(() => {
      if (idToRemove) {
        const timeoutToRemove = timeouts.value.find(t => t.id === idToRemove)
        if (timeoutToRemove) {
          timeoutToRemove.show = false
        }
      }
      const timeout = timeouts.value.find(t => t.id === id)
      if (timeout) {
        timeout.show = true
      }
    }, amount);

    timeouts.value.push(t)
  }

  const addTimeouts = (first: {timeout?: number}, second: {timeout?: number}, action: string, route: string) => {
    const ids = [generateId(), generateId()]
    addTimeout(ids[0], getStandardMessage(action), 'info', first.timeout ? first.timeout : undefined, undefined, route, false);
    addTimeout(ids[1], getSecondMessage(action), 'warning', second.timeout ? second.timeout : undefined, ids[0], route, false);
    return { ids }
  }

  const addLocalTimeouts = (first: {timeout?: number}, second: {timeout?: number}, action: string, route: string) => {
    const ids = [generateId(), generateId()]
    addTimeout(ids[0], getStandardMessage(action), 'info', first.timeout ? first.timeout : undefined, undefined, route, true);
    addTimeout(ids[1], getSecondMessage(action), 'warning', second.timeout ? second.timeout : undefined, ids[0], route, true);
    return { ids }
  }

  const removeTimeout = (id: string) => {
    const timeout = timeouts.value.find(t => t.id === id)
    if (timeout) {
      clearTimeout(timeout.timeout)
      timeouts.value = timeouts.value.filter(t => t.id !== id)
    }
  }

  const removeTimeouts = (ids: string[]) => {
    for (const id of ids) {
      removeTimeout(id)
    }
  }

  const removeAllTimeouts = (route: string) => {
    if (route) {
      for (const timeout of timeouts.value.filter(t => t.route === route)) {
        removeTimeout(timeout.id)
      }
    }
  }

  const getTimeout = (id: string) => {
    if (timeouts.value.find(t => t.id === id)) {
      return timeouts.value.find(t => t.id === id)
    }
  }

  const getShowTimeout = (id: string) => {
    if (getTimeout(id)) {
      return getTimeout(id)?.show
    }
    return false
  }

  const getTimeoutMessage = (id: string) => {
    if (getTimeout(id)) {
      return getTimeout(id)?.message
    }
    return ''
  }

  const getTimeoutType = (id: string) => {
    if (getTimeout(id)) {
      return getTimeout(id)?.type
    }
    return ''
  }

  const hasTimeout = (id: string) => {
    return timeouts.value.find(t => t.id === id)
  }

  return {
    timeouts,
    timeoutMessage,
    errorHeaders,
    getStandardMessage,
    getSecondMessage,
    getTimeoutErrorMessage,
    addTimeout,
    addTimeouts,
    removeTimeout,
    removeTimeouts,
    removeAllTimeouts,
    addLocalTimeouts,
    getTimeout,
    getShowTimeout,
    getTimeoutMessage,
    getTimeoutType,
    hasTimeout
  }
})
