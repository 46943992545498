<script lang="ts" setup>
import { ref } from 'vue';

defineProps<{
  selected?: boolean;
  expandable?: boolean;
  id?: string;
  tabindex?: string;
}>();

const expanded = ref(false);

const emit = defineEmits<{
  (e: 'select'): void
  (e: 'click'): void
  (e: 'focus', payload: FocusEvent): void
  (e: 'keydown', payload: KeyboardEvent): void
}>()
</script>
<template>
  <tr
    :id="id"
    :tabindex="tabindex"
    class="hover:bg-primary hover:bg-opacity-5 hover:cursor-pointer focus:bg-primary/10 focus:outline-0 focus:outline-primary relative"
    :class="[
      selected
        ? 'bg-primary even:bg-primary bg-opacity-5 even:bg-opacity-5'
        : '',
        expandable ? 'col' : 'even:bg-gray even:bg-opacity-80;'
    ]"
    @keypress.space.prevent="() => emit('select')"
    @keypress.enter="() => emit('click')"
    @focus="(e) => $emit('focus', e)"
    @keydown="(e) => $emit('keydown', e)"
    @click="() => {
      emit('click')
      expanded = !expanded  
    }"
  >
    <slot></slot>
    <div v-if="expandable" class="absolute top-[18px] right-2">
      <span class="icon-chevron-down-sm block text-secondary-lighter transition origin-center w-4 h-4" :class="!expanded && 'rotate-90'"></span>
    </div>
  </tr>
  <Transition name="fade">
    <tr v-if="expandable" v-show="expanded" class="expand" :class="expandable ? 'col' : ''">
      <td colspan="100" class="px-3 py-2">
        <span class="text-secondary text-sm">
          <slot name="expand"></slot>
        </span>
      </td>
    </tr>
  </Transition>
</template>

<style>
.col:nth-of-type(4n - 3), .col:nth-of-type(4n - 2) {
  @apply bg-gray bg-opacity-80;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>