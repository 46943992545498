<script lang="ts" setup>
import { TransitionRoot } from "@headlessui/vue";
import { watch } from "vue";
import Spinner from "./SpinnerComp.vue";
const props = defineProps<{
  type: string;
  position?: string;
  show: boolean;
  autoHide?: boolean;
  hideButton?: boolean;
  sm?: boolean;
}>();
const emit = defineEmits<{
  (e: "close"): void;
}>();
const emitClose = () => {
  emit("close");
};
watch(
  () => props.show,
  (newValue) => {
    if (newValue === true && props.autoHide) {
      setTimeout(() => {
        emit("close");
      }, 8000);
    }
  }
);
</script>

<template>
  <TransitionRoot
    :show="show"
    as="template"
    enter="transition-all ease-in-out duration-200 delay-200"
    enter-from="opacity-0 translate-y-1"
    enter-to="opacity-100"
    leave="transition-all ease-in-out duration-200 absolute"
    leave-from="opacity-100"
    leave-to="opacity-0 translate-y-1"
  >
    <div
      style="max-width: calc(100% - 2rem)"
      class="alert rounded-md w-max z-10 whitespace-pre-line"
      :class="[
        sm ? 'p-2' : 'p-4',
        position === 'bottom'
          ? '!fixed bottom-4 left-1/2 -translate-x-1/2'
          : '',
        position === 'top' ? '!fixed top-4 left-1/2 -translate-x-1/2' : '',
        type === 'success' ? 'success bg-success-ultralight' : '',
        type === 'warning' ? 'warning bg-warning-ultralight' : '',
        type === 'info' ? 'info bg-info-ultralight' : '',
        type === 'error' ? 'error bg-primary-ultralight' : '',
        type === 'loading' ? 'loading bg-white border text-secondary-light' : '',
        type === 'loading-info' ? 'loading info bg-info-ultralight' : '',
      ]"
    >
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <span
            v-if="type === 'success'"
            class="icon-succes-sm text-[20px] text-success block"
            aria-hidden="true"
          ></span>
          <span
            v-if="type === 'warning'"
            class="icon-warning-sm text-[20px] text-warning block"
            aria-hidden="true"
          ></span>
          <span
            v-if="type === 'info'"
            class="icon-info-sm text-[20px] text-info block"
            aria-hidden="true"
          ></span>
          <span
            v-if="type === 'error'"
            class="icon-error-sm text-[20px] text-primary block"
            aria-hidden="true"
          ></span>
          <Spinner class="block my-[-2px]" v-if="type === 'loading' || type === 'loading-info'"></Spinner>
        </div>
        <div class="ml-3">
          <p
            class="text-sm font-medium"
            :class="
              type === 'success'
                ? 'text-success-dark'
                : type === 'warning'
                ? 'text-warning-dark'
                : type === 'info' || type === 'loading-info'
                ? 'text-info-dark'
                : type === 'error'
                ? 'text-primary-dark'
                : ''
            "
          >
            <slot></slot>
          </p>
        </div>
        <div v-if="props.hideButton" class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button
              type="button"
              @click="emitClose()"
              data-test="close-button"
              class="inline-flex rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 p-1.5"
              :class="
                type === 'success'
                  ? 'bg-success-ultralight text-success focus:ring-success focus:ring-offset-success'
                  : type === 'warning'
                  ? 'bg-warning-ultralight text-warning focus:ring-warning focus:ring-offset-warning'
                  : type === 'info'
                  ? 'bg-info-ultralight text-info focus:ring-info focus:ring-offset-info'
                  : type === 'error'
                  ? 'bg-primary-ultralight text-primary focus:ring-primary focus:ring-offset-primary'
                  : ''
              "
            >
              <span class="sr-only">Dismiss</span>
              <span
                class="icon-close-sm text-[16px] my-[3px]"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>
./SpinnerComp.vue