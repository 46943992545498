<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import TextButton from "./TextButton.vue";
import CardComp from "./CardComp.vue";
import { vOnClickOutside } from '@vueuse/components'
import type { OnClickOutsideHandler, OnClickOutsideOptions } from "@vueuse/core";
import ButtonComp from "./ButtonComp.vue";

const props = defineProps<{
  type?: string;
  placeholder?: string;
  modelValue: string | number | null;
  icon?: string;
  iconClass?: string;
  name?: string;
  id?: string;
  inputClass?: string;
  required?: boolean;
  autofocus?: boolean;
  pattern?: string;
  message?: string;
  minLength?: number;
  maxLength?: number;
  largeIcon?: boolean;
  disabled?: boolean;
  step?: string;
  min?: string;
  max?: string;
  showReset?: boolean;
  tabindex?: string;
  messageClass?: string;
  smallLabel?: string;
  clearButtonClass?: string;
  default?: string | number | null;
}>();

// const newValue = ref<string | number>(props.modelValue)

const inputRef = ref<HTMLInputElement | null>(null);

const ignore = ref(null)

const onClickOutsideHandler = [
  () => {
    hasFocus.value = false
  },
  { ignore: [ignore, inputRef] },
] as OnClickOutsideOptions


const focus = () => {
  if (inputRef.value) {
    (inputRef.value as HTMLInputElement).focus()
  }
}

onMounted(() => {
  props.autofocus && inputRef.value ? focus() : null;
  if (props.modelValue !== null && props.modelValue !== "" && props.required) {
    validate();
  }
});

watch(() => props.modelValue, () => {
  setTimeout(() => {
    validate()
  }, 100);
})

const valid = ref<boolean>(true);
const validationMessage = ref<string | null>(null);
const validated = ref<boolean>(false);

const validate = () => {
  if (props.type === 'number') {
    if (inputRef.value?.validity.badInput && !inputRef.value.validity.valid) {
      valid.value = false;
      validated.value = true;
      validationMessage.value = props.message
        ? props.message
        : 'Indtast venligst en gyldig værdi';
        return valid.value
    }
  }
  const value = (inputRef.value as any)?.value
  if (props.required && ((inputRef.value as any) && !(inputRef.value as any).value)) {
    valid.value = false;
    validationMessage.value = 'Udfyld venligst dette felt';
  } else if (value && props.minLength && value.length < props.minLength) {
    valid.value = false;
    if (props.maxLength) {
      validationMessage.value = `Den indtastede værdi skal have en længde mellem ${props.minLength} og ${props.maxLength}`
    } else {
      validationMessage.value = `Den indtastede værdi skal være længere end ${props.minLength} tegn`
    }
  } else if (value && props.maxLength && value.length > props.maxLength) {
    valid.value = false;
    if (props.minLength) {
      validationMessage.value = `Den indtastede værdi skal have en længde mellem ${props.minLength} og ${props.maxLength}`
    } else {
      validationMessage.value = `Den indtastede værdi skal være kortere end ${props.maxLength} tegn`
    }
  } else if (props.min && parseFloat(value) < parseFloat(props.min)) {
    valid.value = false;
    validationMessage.value = `Den indtastede værdi skal være større end, eller lig med ${props.min}`
  } else if (props.max && parseFloat(value) > parseFloat(props.max)) {
    valid.value = false;
    validationMessage.value = `Den indtastede værdi skal være mindre end, eller lig med ${props.max}`
  } else if (props.pattern) {
    let pattern = new RegExp(props.pattern);
    if (inputRef.value && !pattern.test((inputRef.value as any).value)) {
      valid.value = false;
      validationMessage.value = props.message
        ? props.message
        : 'Indtast venligst en gyldig værdi';
    } else {
      valid.value = true;
    }
  } else {
    valid.value = true;
  }
  validated.value = true;
  return valid.value
};

const errorMessage = ref<HTMLInputElement | null>(null)

const shake = async () => {
  if (await !validate()) {
    errorMessage.value?.classList.add('shake')
    setTimeout(() => {
      errorMessage.value?.classList.remove('shake')
    }, 500);
  }
}

defineExpose({
  validate,
  inputRef,
  shake
});

const emit = defineEmits<{
  (e: "inputBlur", payload: FocusEvent): void;
  (e: "inputFocus", payload: FocusEvent): void;
  (e: "update:modelValue", payload: string | number): void;
  (e: "reset"):void;
}>();

const hasFocus = ref(false);

const handleBlur = (e: FocusEvent) => {
  emit("inputBlur", e);
  validate();
};

const handleFocus = (e: FocusEvent) => {
  hasFocus.value = true
  emit('inputFocus', e)
}

let timeout: any;

const handleInput = (e: string | number) => {
  clearTimeout(timeout);
  emit("update:modelValue", e);
  timeout = setTimeout(() => {
    validate();
  }, 500);
};

const showResetButton = () => {
  if (props.default) {
    return props.modelValue !== props.default
  } else {
    return props.modelValue !== null && props.modelValue !== ''
  }
}

// const increase = () => {
//   if (props.modelValue && props.step) {
//     emit("update:modelValue", (props.modelValue as number) + parseInt(props.step));
//   } else {
//     emit("update:modelValue", parseInt(props.step as string))
//   }
// }

// const decrease = () => {
//   if (props.modelValue && props.step) {
//     emit("update:modelValue", (props.modelValue as number) - parseInt(props.step));
//   } else {
//     emit("update:modelValue", parseInt('-' + props.step))
//   }
// }

const formatTimeNumber = (number: number) => {
  return number.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
}

const increaseHours = () => {
  if (props.modelValue) {
    let values = (props.modelValue as string).split(':')
    let hours = parseInt(values[0])
    if (hours === 23) {
      hours = 0
    } else {
      hours++
    }
    let newTime = `${formatTimeNumber(hours)}:${values[1]}`
    emit('update:modelValue', newTime)
  }
}

const decreaseHours = () => {
  if (props.modelValue) {
    let values = (props.modelValue as string).split(':')
    let hours = parseInt(values[0])
    if (hours === 0) {
      hours = 23
    } else {
      hours--
    }
    let newTime = `${formatTimeNumber(hours)}:${values[1]}`
    emit('update:modelValue', newTime)
  }
}

const increaseMinutes = () => {
  if (props.modelValue) {
    let values = (props.modelValue as string).split(':')
    let minutes = parseInt(values[1])
    if (minutes === 59) {
      minutes = 0
    } else {
      minutes++
    }
    let newTime = `${values[0]}:${formatTimeNumber(minutes)}`
    emit('update:modelValue', newTime)
  }
}

const decreaseMinutes = () => {
  if (props.modelValue) {
    let values = (props.modelValue as string).split(':')
    let minutes = parseInt(values[1])
    if (minutes === 0) {
      minutes = 59
    } else {
      minutes--
    }
    let newTime = `${values[0]}:${formatTimeNumber(minutes)}`
    emit('update:modelValue', newTime)
  }
}
</script>

<template>
  <div class="input-component relative rounded-md" v-on-click-outside="(onClickOutsideHandler as OnClickOutsideHandler)">
    <div v-if="smallLabel" :class="[
      'text-secondary text-xs absolute top-[-7px] left-2',
      'after:h-[2px] after:bg-white after:w-full after:block after:absolute after:left-0 after:top-[6px] after:z-0'
    ]">
      <span class="relative z-10 text-secondary-lighter">{{ smallLabel }}</span>
    </div>
    <input
      :value="modelValue"
      :type="type ? type : 'text'"
      :placeholder="placeholder"
      :title="placeholder"
      :name="props.name"
      :id="props.id"
      :class="[
        props.inputClass,
        props.icon ? 'pr-10' : '',
        !valid ? 'border-primary' : 'valid',
        disabled ? 'text-secondary/75 placeholder-secondary/25 bg-gray-dark/40' : 'text-secondary placeholder-secondary/50 bg-white',
        //type === 'number' && 'pr-14'
      ]"
      class="input block w-full text-ellipsis shadow-sm rounded-md border-gray-dark focus:border-primary focus:ring-primary sm:text-sm"
      @blur="(event) => handleBlur(event)"
      @focus="(event) => handleFocus(event)"
      @input="(event) => handleInput((event.target as HTMLInputElement).value)"
      :required="props.required"
      ref="inputRef"
      :valid="required && validated && valid ? true : null"
      :pattern="props.pattern"
      :disabled="disabled"
      :step="step"
      :min="min"
      :max="max"
      @keyup.esc="() => emit('reset')"
      :tabindex="tabindex"
      @vue:mounted="() => autofocus ? focus() : null"
    />
    <div
      class="absolute top-2 cursor-pointer"
      :class="[type === 'search' ? 'right-2' : 'right-9', clearButtonClass]"
      v-if="showReset && showResetButton()"
      @click="() => emit('reset')"
    >
      <span class="text-secondary/50 hover:text-secondary" :class="type === 'time' ? 'icon-reload-sm' : 'icon-close-sm'"></span>
    </div>
    <!-- <div v-if="type === 'number'" class="absolute top-[5px] right-3 flex flex-col justify-center">
      <TextButton sm class="focus:ring-0" @click="(e) => increase()"><span class="icon-chevron-up-sm"></span></TextButton>
      <TextButton sm class="focus:ring-0" @click="(e) => decrease()"><span class="icon-chevron-down-sm"></span></TextButton>
    </div> -->
    <div
      v-if="props.icon"
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
    >
      <span
        v-if="props.icon"
        :class="[
          `icon-${props.icon}`,
          largeIcon ? 'text-[32px]' : 'text-[24px]',
          iconClass
        ]"
        class="text-gray-darker"
        aria-hidden="true"
      ></span>
    </div>
    <span ref="errorMessage" v-if="!valid" class="mt-1 block text-xs text-primary validation-message" :class="messageClass">{{
      validationMessage
    }}</span>
    <Transition name="fade">
      <template v-if="type === 'time' && hasFocus">
        <CardComp nopadding class="p-2 !absolute left-0 right-0 z-10 top-full mt-1 border" ref="ignore">
          <div class="flex gap-2 w-full items-center justify-center text-2xl text-secondary">
            <span class="flex flex-col">
              <TextButton @click="() => increaseHours()"><span class="icon-chevron-up-sm text-xl"></span></TextButton>
              {{ (modelValue as string)?.split(':')[0] }}
              <TextButton @click="() => decreaseHours()"><span class="icon-chevron-down-sm text-xl"></span></TextButton>
            </span>
            <span>:</span>
            <span class="flex flex-col">
              <TextButton @click="() => increaseMinutes()"><span class="icon-chevron-up-sm text-xl"></span></TextButton>
              {{ (modelValue as string)?.split(':')[1] }}
              <TextButton @click="() => decreaseMinutes()"><span class="icon-chevron-down-sm text-xl"></span></TextButton>
            </span>
          </div>
          <template v-if="props.default">
            <ButtonComp
              sm
              color="primary"
              class="mt-4 w-full"
              :disabled="props.modelValue === props.default"
              @click="() => emit('update:modelValue', props.default as string)"
            >Gendan standardværdi</ButtonComp>
          </template>
        </CardComp>
      </template>
    </Transition>
  </div>
</template>

<style>
.input::-webkit-search-cancel-button {
  position: relative;
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  background: url("./icons/close.svg") no-repeat 50% 50%;
  background-size: contain;
  display:none
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1;
  /*appearance: none;*/
}


/* input[type=number] {
  appearance: initial;
  -moz-appearance: textfield;
} */

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.shake {
  animation-name: shake;
  animation-duration: .5s;
  animation-iteration-count: 1;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
