<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import Checkbox from './CheckboxComp.vue';

const props = defineProps<{
  options: {text: string; value: string;}[];
  modelValue: string[];
  name?: string;
  disabled?: boolean;
  tabindex?: string;
}>()

const localModel = ref<string[]>([])

onMounted(() => {
  localModel.value = props.modelValue
})

watch(() => props.modelValue, () => {
  localModel.value = props.modelValue
})

const emit = defineEmits<{
  (e: 'update:model-value', payload: string[]): void
}>()

const handleSelected = (e: any) => {
  if (e.target.checked && props.modelValue.indexOf(e.target.value) === -1) {
    localModel.value.push(e.target.value);
  } else if (!e.target.checked && props.modelValue.indexOf(e.target.value) !== -1) {
    localModel.value.splice(props.modelValue.indexOf(e.target.value), 1)
  }
  emit('update:model-value', localModel.value)
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <Checkbox
      v-for="(item, index) of options"
      :key="index"
      name="type"
      :checkbox-id="item.value"
      :value="item.value"
      :checked="localModel.includes(item.value)"
      @change="(e) => handleSelected(e)"
      :disabled="disabled"
      :tabindex="tabindex"
    >{{ item.text }}</Checkbox>
  </div>
</template>./CheckBox.vue./CheckboxComp.vue