import { type RouteLocationNormalized, type Router } from "vue-router";
import { msalInstance, loginRequest } from "../authConfig";
import { InteractionType, type PopupRequest, PublicClientApplication, type RedirectRequest } from "@azure/msal-browser";
// import { useUsersStore } from "@/stores/user";
import { useTimeoutsStore } from "@/stores/timeouts";

export function registerGuard(router: Router) {
    router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        const timeoutStore = useTimeoutsStore();
        if (from.name !== to.name) {
            timeoutStore.removeAllTimeouts(from.name as string);
            timeoutStore.removeAllTimeouts('other');
        }
        // const userStore = useUsersStore();
        // if (to.meta.requiresAuth === false) {
        //     return true;
        // }
        // if (await isAuthenticated(msalInstance, InteractionType.Redirect, loginRequest) === false) {
        //     return '/sign-in';
        // }
        // if (!userStore.permissions.length) {
        //     try {
        //         const response = await msalInstance.acquireTokenSilent({
        //             ...loginRequest
        //         }).catch(async (e) => {
        //             throw e;
        //         });
        //         await userStore.getPermissions(response.accessToken).catch((error: any) => {
        //             console.debug(error)
        //         })
        //     }
        //     catch (error) {
        //         console.debug(error)
        //         return '/sign-in'
        //     }
        // }
        // if (to.meta.permission && !userStore.checkPermission(to.meta.permission as string)) {
        //     if (to.name === 'home') {
        //         return '/failed'
        //     }
        //     return from.path
        // }
        // const request = {
        //     ...loginRequest,
        //     redirectStartPage: to.fullPath
        // }
        // const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request);
        // return shouldProceed || '/failed';

        if (to.meta.requiresAuth) {
            const request = {
                ...loginRequest,
                redirectStartPage: to.fullPath
            }
            const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request);
            return shouldProceed || '/failed';
        }
    
        return true;
    });
}

export async function isAuthenticated(instance: PublicClientApplication, interactionType: InteractionType, loginRequest: PopupRequest|RedirectRequest): Promise<boolean> {    
    // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not  
    return instance.handleRedirectPromise().then(() => {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
            return true;
        }

        // User is not signed in and attempting to access protected route. Sign them in.
        if (interactionType === InteractionType.Popup) {
            return instance.loginPopup(loginRequest).then(() => {
                return true;
            }).catch(() => {
                return false;
            })
        } else if (interactionType === InteractionType.Redirect) {
            return instance.loginRedirect(loginRequest).then(() => {
                return true;
            }).catch(() => {
                return false;
            });
        }

        return false;
    }).catch(() => {
        return false;
    });
}