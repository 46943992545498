
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'
import router from './router'

import './assets/main.css'
import "./assets/ok-icons/style.css";

import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { type AuthenticationResult, EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/NavigationClient";

// try {
  // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigationClient = new CustomNavigationClient(router);
  msalInstance.setNavigationClient(navigationClient);

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
// } catch (error) {
//   console.log('hej', error)
// }

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(VueAxios, axios)
app.use(msalPlugin, msalInstance);

app.provide('axios', app.config.globalProperties.axios);

app.directive("empty", {
  mounted: (el: HTMLElement, binding) => {
    if (binding.value) {
      const string = el.innerText;
      if (!string) {
        el.classList.add('italic');
        el.classList.add('text-secondary/50');
        el.innerText = 'Ingen værdi';
      }
    }
  },
  updated: (el: HTMLElement, binding) => {
    if (binding.value) {
      const string = el.innerText;
      if (!string) {
        el.classList.add('italic');
        el.classList.add('text-secondary/50');
        el.innerText = 'Ingen værdi';
      }
    }
  },
});

router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount('#app');
})
