<script setup lang="ts">
import { ref, computed } from "vue";
import { onMounted } from "vue";
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated";
import { useRouter } from "vue-router";
import Header from '@/components/HeaderComp.vue';

const isAuthenticated = useIsAuthenticated();
const router = useRouter();

onMounted(() => {
  if (!isAuthenticated) {
    router.push("/sign-in")
  }
})

const navigation = computed(() => {
  return [
  {
      title: 'Søgning',
      name: 'home',
      href: '/'
    },
    {
      title: 'Udvidet søgning',
      name: 'advanced search',
      href: '/advanced-search'
    },
    {
      title: 'Administration',
      name: 'admin',
      href: '/admin',
      permission: 'admin:view'
    }
  ];
});

const userNavigation = computed(() => {
  return [
    { name: "Min profil", href: "/profile" },
    // { name: $t('settings'), href: '#' },
  ];
});

const scrollTop = ref(0);

const handleScroll = (i: number) => {
  scrollTop.value = i;
};
</script>

<template>
  <Header :items="navigation" :user-items="userNavigation"></Header>
  <!-- <div class="flex flex-1 flex-col md:pl-[300px] h-full"> -->
    <main
      id="main"
      :top="scrollTop"
      class="flex-auto overflow-auto"
      style="height: calc(100vh - 64px)"
      @scroll="e => handleScroll((e as any).target.scrollTop)"
    > <!--  -->
      <div class="py-6">
        <RouterView />
      </div>
    </main>
  <!-- </div> -->
</template>
