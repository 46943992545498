<script lang="ts" setup>
import { computed, ref, onMounted } from "vue";
// import { Listbox, ListboxButton, ListboxLabel, ListboxOptions } from '@headlessui/vue'
// import SelectOption from './SelectOption.vue';
import { onClickOutside } from "@vueuse/core";
import Alert from "./AlertComp.vue";
import Overlay from "./OverlayComp.vue";
import FormattedError from "./formattedError.vue";
import type { ErrorResponse } from "@/types";

const props = defineProps<{
  modelValue: string | number | null;
  hideChevron?: boolean;
  hideCheck?: boolean;
  options:
    | {
        value?: string | number | null;
        text?: string;
        icon?: {};
      }[]
    | any[];
  error: Error & ErrorResponse | null;
  loading?: boolean;
  buttonClass?: string;
  required?: boolean;
  class?: string;
  id?: string;
  name?: string;
  message?: string;
  colorScheme?: string;
  tabindex?: string;
  buttonId?: string;
  optionsId?: string;
  disabled?: boolean;
}>();

//const $t: Function = inject("$t") as Function;

const selected = computed(() => {
  if (props.options.find(o => o.value === props.modelValue)) {
    return props.options.find((o) => {
      if (o.value !== undefined && o.value === props.modelValue) {
        return true;
      } else if (o === props.modelValue) {
        return true;
      }
      return false;
    });
  } else {
    return props.options.find(o => o.value === null)
  }
});

const current = ref<string | number | null>(null)
onMounted(() => {
  if(props.modelValue) {
    current.value = props.modelValue;
  }
})

const emit = defineEmits<{
  (e: "update:modelValue", payload: string | number | null): void;
  (e: "keyup", payload: KeyboardEvent): void;
  (e: "keydown", payload: KeyboardEvent): void;
  (e: "select"): void;
}>();

const handleChange = (event: string | number | null) => {
  emit("update:modelValue", event);
  current.value = event;
  validate();
  target.value?.focus();
  selectedOption.value = null;
  emit('select')
  setTimeout(() => {
    show.value = false;
  }, 100);
};

const show = ref(false);
const target = ref<HTMLElement | null>(null);
onClickOutside(target, () => (show.value = false));

const optionsWrap = ref<HTMLElement | null>(null);

const openOptions = () => {
  show.value = true;
  setTimeout(() => {
    nextOption();
  }, 10);
};

const valid = ref<boolean>(true);
const validationMessage = ref<string | null>(null);
const validated = ref<boolean>(false);

const validate = () => {
  if (props.required && current.value === null) {
    validationMessage.value = props.message ? props.message : 'Udfyld venligst dette felt'
    valid.value = false
  } else {
    valid.value = true
  }
  validated.value = true
  return valid.value
}

const errorMessage = ref<HTMLInputElement | null>(null)

const shake = async () => {
  if (await !validate()) {
    errorMessage.value?.classList.add('shake')
    setTimeout(() => {
      errorMessage.value?.classList.remove('shake')
    }, 500);
  }
}

defineExpose({
  validate,
  shake
});

const selectedOption = ref<number | null>(null);

const nextOption = () => {
  if (selectedOption.value === null) {
    selectedOption.value = 0;
  } else if (selectedOption.value < props.options.length - 1) {
    selectedOption.value = selectedOption.value + 1;
  }
  let element = optionsWrap.value?.children[selectedOption.value] as HTMLElement
  element.focus()
}

const prevOption = () => {
  if (selectedOption.value !== 0 && selectedOption.value !== null) {
    selectedOption.value = selectedOption.value - 1;
    let element = optionsWrap.value?.children[selectedOption.value] as HTMLElement
    element.focus()
  } else {
    show.value = false;
    selectedOption.value = null;
    target.value?.focus()
  }
}
</script>

<template>
  <div class="relative" :class="props.class" :id="id">
    <Overlay :show="loading" class="rounded-md" spinner></Overlay>
    <input type="hidden" :value="selected.value" :required="required" :name="name" :valid="required && validated && valid ? true : null" />
    <button 
      :id="buttonId"
      type="button"
      :class="[
        !props.hideChevron ? 'pr-10' : 'pr-3',
        buttonClass,
        !valid ? 'border-primary' : '',
        disabled ? colorScheme === 'dark' ? 'bg-secondary/50' : 'bg-gray-dark/40' : colorScheme === 'dark' ? 'bg-secondary/25' : 'bg-white'
      ]"
      class="relative w-full cursor-default rounded-md border border-gray-dark py-2 pl-3 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label"
      @click="() => show = !show"
      ref="target"
      @keyup="(e) => emit('keyup', e)"
      @keyup.down="openOptions()"
      @blur="() => validate()"
      :tabindex="tabindex"
      :disabled="disabled"
    >
      <span
        class="block truncate"
        :class="[
          selected.value === null ? 'text-opacity-50' : '',
          colorScheme === 'dark' ? 'text-secondary' : 'text-secondary',
          disabled ? 'text-opacity-75' : ''
        ]"
      >
        <component
          v-if="selected.icon"
          :is="selected.icon"
          class="w-[20px] mr-2 inline-block"
        ></component>
        {{ selected && selected.text !== null ? selected.text : selected }}
      </span>
      <span
        v-if="!props.hideChevron"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        :class="disabled ? 'opacity-50' : ''"
      >
        <span class="icon-select text-[24px] text-secondary" aria-hidden="true"></span>
      </span>
    </button>
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ul
        v-show="show"
        class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-option-3"
        ref="optionsWrap"
        keyup.prevent
        keydown.prevent
        :id="optionsId"
      >
        <li
          :class="[
            'text-secondary hover:bg-primary-ultralight/50 relative cursor-default select-none py-2 pl-3 focus:bg-primary-ultralight/50 focus:outline-none',
            hideCheck ? 'pr-3' : 'pr-9',
          ]"
          role="option"
          tabindex="-1"
          aria-selected="false"
          v-for="(item, index) in options"
          :key="index"
          keyup.prevent
          keydown.prevent
          @keyup.down.prevent="nextOption()"
          @keyup.up.prevent="prevOption()"
          @keyup.enter.prevent="() =>
            handleChange(
              item.value !== null
                ? item.value
                : item.value === null
                ? null
                : item
            )
          "
          @keyup.space="() =>
            handleChange(
              item.value !== null
                ? item.value
                : item.value === null
                ? null
                : item
            )
          "
          @click="() =>
            handleChange(
              item.value !== null
                ? item.value
                : item.value === null
                ? null
                : item
            )
          "
        >
          <span
            :class="[
              selected.value === item.value ? 'font-semibold' : 'font-normal',
              'block truncate',
              item.value === null ? 'text-secondary/50' : 'text-secondary'
            ]"
          >
            <component
              v-if="item.icon"
              :is="item.icon"
              class="w-[20px] mr-2 inline-block"
            ></component>
            {{ item.text !== null ? item.text : item }}
          </span>
          <span
            v-if="selected.value === item.value && !hideCheck"
            class="text-primary absolute inset-y-0 right-0 flex items-center pr-4"
          >
            <span class="icon-check-sm" aria-hidden="true"></span>
          </span>
        </li>
      </ul>
    </transition>
    <Alert
      class="min-w-full p-1 mt-1"
      type="error"
      :show="error !== undefined && error !== null"
      >
        <FormattedError :error="error"></FormattedError>
      </Alert
    >
  </div>
  <span ref="errorMessage" v-if="!valid" class="block mt-1 text-xs text-primary">
    {{ validationMessage }}
  </span>
</template>

<style>
.shake {
  animation-name: shake;
  animation-duration: .5s;
  animation-iteration-count: 1;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>./OverlayComp.vue./AlertComp.vue