<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import Card from './CardComp.vue';
import { onClickOutside } from '@vueuse/core'

const props = defineProps<{
  parentId: string;
  show?: boolean;
  class?: string;
}>();

const emit = defineEmits<{
  (e: 'update:show', payload: boolean): void
}>()

const popup = ref(null);
const parentElement = ref<HTMLElement | null>(null);
const parentTop = ref<number>(0);
const parentLeft = ref<number>(0);
const parentRight = ref<number>(0);
const parentBottom = ref<number>(0);
const parentWidth = ref<number>(0);
const parentHeight = ref<number>(0);
const windowW = window.innerWidth;
const windowH = window.innerHeight;
const style = ref('');

onClickOutside(popup, () => {
  emit('update:show', false)
});

onMounted(() => {
   parentElement.value = document.getElementById(props.parentId);
   const bounding = parentElement.value?.getBoundingClientRect();
   if (bounding) {
     parentTop.value = bounding.top;
     parentLeft.value = bounding.left;
     parentRight.value = bounding.right;
     parentBottom.value = bounding.bottom;
     parentWidth.value = bounding.width;
     parentHeight.value = bounding.height;
   }

  if (parentTop.value > windowH / 2) {
    style.value += `bottom: ${windowH - parentTop.value + 10}px; `;
  } else {
    style.value += `top: ${parentTop.value + parentHeight.value + 10}px; `;
  }
  if (parentLeft.value > windowW / 2) {
    style.value += `right: ${windowW - parentRight.value}px;`
  } else {
    style.value += `left: ${parentLeft.value}px;`
  }
})


</script>

<template>
  <Teleport to="#app">
    <Card ref="popup" nopadding v-show="show" class="!fixed z-30 shadow-md border" :class="props.class" :style="style">
      <slot></slot>
    </Card>
  </Teleport>
</template>./CardComp.vue