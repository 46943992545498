<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItems } from '@headlessui/vue';
import { useMsal } from '../composition-api/useMsal';
import Logo from './LogoComp.vue';
import { useRouter } from "vue-router";
import { computed } from 'vue';
import { useUsersStore } from '@/stores/user';

const { instance } = useMsal();
const router = useRouter();
const userStore = useUsersStore();

const logoutPopup = () => {
  instance.logoutPopup({
    mainWindowRedirectUri: "/"
  }).then(() => {
    router.push("/sign-in");
  });
}

const routeMatches = computed(() => {
  return router.currentRoute.value.matched || []
})

defineProps<{
  items: {
    title: string;
    name: string;
    href: string;
    permission?: string;
  }[];
  userItems: {
    name: string;
    href: string;
    permission?: string;
  }[];
}>();
</script>

<template>
  <Disclosure as="nav" id="header" class="bg-white shadow sticky top-0 z-30" v-slot="{ open }">
    <div class="mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-secondary/60 hover:bg-gray hover:text-secondary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
            <span class="sr-only">Open main menu</span>
            <span v-if="!open" class="icon-menu-sm block text-[24px]" aria-hidden="true" ></span>
            <span v-else class="icon-close-sm block text-[24px]" aria-hidden="true" ></span>
          </DisclosureButton>
        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex items-center">
            <Logo class="logo w-[50px] h-auto mr-3" />
            <span class="text-2xl text-secondary mb-[3px]">WebArkiv</span>
          </div>
          <div id="main-menu" class="hidden sm:ml-6 sm:flex sm:space-x-8">
            <template v-for="(item, index) of items" :key="index">
              <RouterLink
                v-if="item.permission ? userStore.checkPermission(item.permission) : true"
                :to="item.href"
                as="a"
                class="routerlink inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium transition focus-visible:outline-primary"
                :class="routeMatches.find(m => m.name === item.name) ? 
                  'border-primary text-secondary' :
                  'border-transparent text-secondary/60 hover:border-gray-darker hover:text-secondary'
                "
              >{{ item.title }}</RouterLink>
            </template>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <!-- <Button v-if="!isAuthenticated" sm @click="() => loginPopup()">Login</Button>
          <Button v-else sm @click="() => logoutPopup()">Logout</Button> -->
          <!-- Profile dropdown -->
          <Menu as="div" id="user-menu" class="relative ml-3">
            <div>
              <MenuButton class="flex max-w-xs items-center border border-gray-darker rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                <span class="sr-only">Open user menu</span>
                <span class="icon-avatar text-[25px] p-[6px] text-secondary"></span>
              </MenuButton>
            </div>
            <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              id="user-menu-items" class="profile-contents absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <template v-for="(item, index) of userItems" :key="index">
                <RouterLink
                  v-if="item.permission ? userStore.checkPermission(item.permission) : true"
                  :to="item.href"
                  class="routerlink block px-4 py-2 text-sm text-secondary/75 hover:bg-gray hover:text-secondary"
                >{{ item.name }}</RouterLink>
              </template>
              <span
                class="block px-4 py-2 text-sm text-secondary/75 hover:bg-gray hover:text-secondary cursor-pointer"
                @click="() => logoutPopup()"
              >Log af</span>
            </MenuItems>
          </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 pb-4 pt-2">
        <DisclosureButton
          v-for="(item, index) of items"
          :key="index"
          as="a"
          :href="item.href"
          class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
          :class="routeMatches.find(m => m.name === item.name) ? 
            'border-primary bg-primary-ultralight/50 text-primary' : 
            'border-transparent text-secondary/60 hover:border-gray-darker hover:bg-gray hover:text-secondary'
          "
        >{{ item.title }}</DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>./LogoComp.vue