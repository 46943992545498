import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios'

const getConfig = () => {
  return new Promise(function (resolve, reject) {
    axios.get('/api/v1/config').then((response) => {
      resolve(response.data)
    }).catch((error) => {
      reject(error)
    });
  })
}

const config = {
  clientId: '',
  authority: '',
  scopes: [],
  redirectUri: ''
};

export let configLoaded = false;

await getConfig().then((response: any) => {
  config.clientId = response.clientId
  config.scopes = response.scopes
  config.authority = response.authority
  config.redirectUri = response.redirectUri
  configLoaded = true
}).catch((error) => {
  console.debug('hejsa', error)
})

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: config.clientId,
    authority: config.authority,
    redirectUri: config.redirectUri, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: config.redirectUri // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
      loggerOptions: {
          loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
                  default:
                      return;
              }
          },
          logLevel: LogLevel.Warning
      }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: config.scopes,
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
