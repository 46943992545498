<template>
  <code>
    <slot></slot>
  </code>
</template>

<style scoped>
code {
  @apply bg-gray-dark/50 px-1.5 py-1 rounded;
}
</style>