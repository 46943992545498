<script lang="ts" setup>
import type { ErrorResponse } from '@/types';
import type { AxiosError } from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useTimeoutsStore } from '@/stores/timeouts';

const timeoutStore = useTimeoutsStore();
const props = defineProps<{
  error: Error & ErrorResponse | null
  action?: string;
  entity?: string;
}>()

const timeout = ref(false);

const method = computed(() => {
  if (props.action) {
    return props.action
  }
  else if ((props.error as AxiosError)?.config?.method) {
    return (props.error as AxiosError)?.config?.method
  }
  return 'get'
})

const action = ref<{[key: string]: string}>({
  get: 'hente',
  put: 'gemme',
  post: 'oprette',
  delete: 'slette'
})

const actionString = computed(() => {
  if (props.entity) {
    if (props.action) {
      return `${props.action} ${props.entity}`
    }
    return `${action.value[method.value as string]} ${props.entity}`
  }
  return undefined
})

onMounted(() => {
  if ((props.error as AxiosError)?.code && ((props.error as AxiosError)?.code === "ECONNABORTED" || (props.error as AxiosError)?.response?.status === 408)) {
    timeout.value = true
  }
})
</script>

<template>
  <div class="flex flex-col gap-2">
    <strong v-if="error && error.response && error.response.data">
      {{ `${error.response.data.status || ''} ${error.response.data.titleDanish || error.response.data.title || ''}` }}
    </strong>
    <slot v-if="!timeout"></slot>
    <span v-if="error && !timeout">
      {{error && error.response && error.response.data ? `${error.response.data.detailsDanish || error.response.data.details || ''}` : error?.message ? error.message : ''}}
    </span>
    <span v-if="timeout">
      <span class="timeout-message">{{ timeoutStore.getTimeoutErrorMessage(actionString) }}</span>
    </span>
  </div>
</template>

