<script lang="ts" setup>
import { ref, computed, onMounted, watch } from "vue";
import Input from "./InputComp.vue";
import SelectInput from "./SelectInput.vue";
import TextButton from "./TextButton.vue";

const props = defineProps<{
  selected?: string;
  showPrev?: boolean;
  showNext?: boolean;
}>();

const now = new Date();
const year = ref(now.getFullYear());
const month = ref(now.getMonth());
const selectedDate = ref<Date | null>(null);

onMounted(() => {
  if (props.selected) {
    selectedDate.value = constructDate(props.selected) as Date;
  }
})

watch(() => props.selected, (newval) => {
  if (newval !== '') {
    selectedDate.value = constructDate(newval as string) as Date;
  } else {
    selectedDate.value = null;
  }
})

watch(() => selectedDate.value, (newval) => {
  if (newval !== null) {
    year.value = newval?.getFullYear() as number;
    month.value = newval?.getMonth() as number;
  } else {
    year.value = now.getFullYear();
    month.value = now.getMonth();
  }
})

const constructDate = (datestring: string) => {
  if (datestring.includes('-') && datestring.length === 10) {
    let parts = [] as number[];
    let substrings = datestring.split('-');
    for (let string of substrings) {
      parts.push(parseInt(string))
    }
    let date = new Date();
    date.setDate(parts[0]);
    date.setMonth(parts[1] - 1);
    date.setFullYear(parts[2]);
    return date;
  } else if (!datestring.includes('-') && datestring.length === 8) {
    let parts = [
      parseInt(datestring.substring(0,2)),
      parseInt(datestring.substring(2,4)),
      parseInt(datestring.substring(4,8))
    ] as number[];
    let date = new Date();
    date.setDate(parts[0]);
    date.setMonth(parts[1] - 1);
    date.setFullYear(parts[2]);
    return date;
  }
}

const deconstructDate = (date: Date) => {
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  let dateString =  ('0' + day).slice(-2) + '-' +  ('0' + (month + 1)).slice(-2) + '-' + year;
  return dateString;
}

const emit = defineEmits<{
  (e: "dateSelect", payload: string): void;
}>();

const handleSelect = (date: Date) => {
  emit("dateSelect", deconstructDate(date));
};

const months = computed(() => [
  {
    text: "Januar",
    value: 0,
  },
  {
    text: "Februar",
    value: 1,
  },
  {
    text: "Marts",
    value: 2,
  },
  {
    text: "April",
    value: 3,
  },
  {
    text: "Maj",
    value: 4,
  },
  {
    text: "Juni",
    value: 5,
  },
  {
    text: "Juli",
    value: 6,
  },
  {
    text: "August",
    value: 7,
  },
  {
    text: "September",
    value: 8,
  },
  {
    text: "Oktober",
    value: 9,
  },
  {
    text: "November",
    value: 10,
  },
  {
    text: "December",
    value: 11,
  },
]);

const getMonthDays = (month: number) => {
  var date = new Date(year.value, month);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  if (days.length && days[0].getDay() !== 0 && days[0].getDay() !== 1) {
    let offset = days[0].getDay() - 2;
    let i = 0;
    let newDate = new Date(year.value, month);
    while (i <= offset) {
      newDate.setDate(newDate.getDate() - 1);
      days.unshift(new Date(newDate));
      i++;
    }
  } else if (days.length && days[0].getDay() === 0) {
    let i = 0;
    let newDate = new Date(year.value, month);
    while (i <= 5) {
      newDate.setDate(newDate.getDate() - 1);
      days.unshift(new Date(newDate));
      i++;
    }
  }
  if (days.length < 35) {
    let offset = 35 - days.length - 1;
    let i = 0;
    let newDate = new Date(days[days.length - 1]);
    while (i <= offset) {
      newDate.setDate(newDate.getDate() + 1);
      days.push(new Date(newDate));
      i++;
    }
  }
  if (days.length > 35) {
    let offset = 42 - days.length - 1;
    let i = 0;
    let newDate = new Date(days[days.length - 1]);
    while (i <= offset) {
      newDate.setDate(newDate.getDate() + 1);
      days.push(new Date(newDate));
      i++;
    }
  }
  return days;
};

const isSelected = (date: Date) => {
  if (selectedDate.value) {
    if (selectedDate.value.toDateString() === new Date(date).toDateString()) {
      return true;
    }
  }
  return false;
};

const isToday = (date: Date) => {
  return new Date(date).toDateString() === new Date().toDateString();
};

const isCurrentMonth = (date: Date) => {
  return new Date(date).getMonth() === month.value;
};

const prev = () => {
  if (month.value === 0) {
    month.value = 11;
    year.value = year.value - 1;
  } else {
    month.value = month.value - 1;
  }
};

const next = () => {
  if (month.value === 11) {
    month.value = 0;
    year.value = year.value + 1;
  } else {
    month.value = month.value + 1;
  }
};
</script>

<template>
  <template v-for="(m, monthIdx) in months" :key="monthIdx">
    <section
      :class="[
        monthIdx === months.length - 1 && 'hidden md:block',
        'text-center',
      ]"
      v-if="monthIdx === month"
    >
      <div class="flex gap-4 justify-center">
        <TextButton @click="prev()" class="w-[30px]" v-if="props.showPrev">
          <span class="icon-chevron-left text-[24px]"></span>
        </TextButton>
        <SelectInput
          class="month-select w-[130px]"
          v-model="month"
          v-model:options="months"
          :error="null"
        ></SelectInput>
        <Input
          type="number"
          class="year-input max-w-[100px]"
          v-model="year"
          v-model:options="months"
        />
        <TextButton @click="next()" class="w-[30px]" v-if="props.showNext">
          <span class="icon-chevron-right text-[24px]"></span>
        </TextButton>
      </div>
      <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-darker">
        <div>M</div>
        <div>T</div>
        <div>O</div>
        <div>T</div>
        <div>F</div>
        <div>L</div>
        <div>S</div>
      </div>
      <div
        class="isolate mt-2 grid grid-cols-7 rounded-lg bg-white text-sm shadow ring-1 ring-gray-200"
      >
        <button
          class="date-button"
          v-for="(day, dayIdx) in getMonthDays(months.indexOf(m))"
          :key="dayIdx"
          @click="() => handleSelect(day)"
          type="button"
          :title="day.toDateString()"
          :class="[
            isCurrentMonth(day)
              ? 'bg-white text-secondary hover:bg-gray'
              : 'bg-gray text-gray-darker hover:bg-gray/75',
            dayIdx === 0 && 'rounded-tl-lg',
            dayIdx === 6 && 'rounded-tr-lg',
            dayIdx === getMonthDays(months.indexOf(m)).length - 7 &&
              'rounded-bl-lg',
            dayIdx === getMonthDays(months.indexOf(m)).length - 1 &&
              'rounded-br-lg',
            'relative py-1.5 focus:z-10',
          ]"
        >
          <time
            :datetime="day.toDateString()"
            :class="[
              isToday(day) && !isSelected(day) && 'today',
              isSelected(day) && 'selected-date bg-primary font-semibold text-white w-7 rounded-full',
              'mx-auto flex h-7 items-center justify-center'
            ]"
            ><span class="relative z-10">{{ day.getDate() }}</span></time
          >
        </button>
      </div>
    </section>
  </template>
</template>

<style>
.date-button + .date-button {
  @apply border-l border-secondary/10;
}

.date-button {
  @apply border-t border-secondary/10;
}

.today:before {
  content: "";
  width: 6px;
  height: 6px;
  @apply bg-primary absolute rounded-full right-[5px] top-[5px];
}

.between:after {
  content: "";
  @apply absolute left-[-1px] w-full h-7 z-0 border-l border-primary-dark/25;
}

.between-outside:after {
  content: "";
  @apply absolute left-[-1px] w-full h-7 z-0 border-l border-primary/10;
}

.start:after {
  content: "";
  @apply absolute right-[-1px] w-5/6 rounded-l-full h-7 bg-primary z-0 border-r border-primary-dark;
}

.end:after {
  content: "";
  @apply absolute left-[-1px] w-5/6 rounded-r-full h-7 bg-primary z-0 border-l border-primary-dark;
}
</style>
./InputComp.vue