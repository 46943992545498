<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

const props = defineProps<{
  disabled?: boolean;
  modelValue: string;
  rows?: number;
  pattern?: string;
  message?: string;
  messageClass?: string;
  required?: boolean;
  class?: string;
  minLength?: number;
  maxLength?: number;
  id?: string;
  tabindex?: string
}>()

const localValue = ref('')

onMounted(() => {
  localValue.value = props.modelValue;
  setTimeout(() => {
    if (props.modelValue !== null && props.modelValue !== "" && props.required) {
      validate();
    }
  }, 100);
})

watch(() => props.modelValue, () => {
  localValue.value = props.modelValue
  setTimeout(() => {
    validate()
  }, 100);
})

const inputRef = ref<HTMLInputElement | null>(null);
const valid = ref<boolean>(true);
const validationMessage = ref<string | null>(null);
const validated = ref<boolean>(false);

const validate = () => {
  const value = (inputRef.value as any)?.value
  if (props.required && ((inputRef.value as any) && !(inputRef.value as any).value)) {
    valid.value = false;
    validationMessage.value = 'Udfyld venligst dette felt';
  } else if (value && props.minLength && value.length < props.minLength) {
    valid.value = false;
    if (props.maxLength) {
      validationMessage.value = `Den indtastede værdi skal have en længde mellem ${props.minLength} og ${props.maxLength}`
    } else {
      validationMessage.value = `Den indtastede værdi skal være længere end ${props.minLength} tegn`
    }
  } else if (value && props.maxLength && value.length > props.maxLength) {
    valid.value = false;
    if (props.minLength) {
      validationMessage.value = `Den indtastede værdi skal have en længde mellem ${props.minLength} og ${props.maxLength}`
    } else {
      validationMessage.value = `Den indtastede værdi skal være kortere end ${props.maxLength} tegn`
    }
  } else if (props.pattern) {
    let pattern = new RegExp(props.pattern);
    if (inputRef.value && !pattern.test((inputRef.value as any).value)) {
      valid.value = false;
      validationMessage.value = props.message
        ? props.message
        : 'Indtast venligst en gyldig værdi';
    } else {
      valid.value = true;
    }
  } else {
    valid.value = true;
  }
  validated.value = true;
  return valid.value
}

const errorMessage = ref<HTMLInputElement | null>(null)

const shake = async () => {
  if (await !validate()) {
    errorMessage.value?.classList.add('shake')
    setTimeout(() => {
      errorMessage.value?.classList.remove('shake')
    }, 500);
  }
}

defineExpose({
  validate,
  inputRef,
  shake
})

defineEmits<{
  (e: 'keyup', payload: KeyboardEvent): void
  (e: 'input', payload: Event): void
  (e: 'update:model-value', payload: any): void
}>()
</script>

<template>
  <textarea
    class="w-full border-gray-dark rounded-md text-sm focus:border-primary focus:ring-primary"
    :class="[props.class, disabled ? 'bg-gray-dark/40 text-secondary/75' : 'bg-white text-secondary']"
    :rows="rows ? rows : 5"
    :disabled="disabled"
    v-model="localValue"
    @update:model-value="$emit('update:model-value', localValue)"
    ref="inputRef"
    :required="required"
    :valid="required && validated && valid ? true : null"
    :id="props.id"
    @keyup="(e) => $emit('keyup', e)"
    @input="(e) => $emit('input', e)"
    :tabindex="tabindex"
  ></textarea>
  <span
    ref="errorMessage"
    v-if="!valid"
    class="-mt-1 block text-xs text-primary validation-message"
    :class="messageClass"
  >{{ validationMessage }}</span>
</template>