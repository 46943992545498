<script lang="ts" setup>
import Input from './InputComp.vue';
import TextButton from './TextButton.vue';
import PopUp from './PopUp.vue';
import Calendar from './CalendarComp.vue';
import { CalendarIcon } from '@heroicons/vue/20/solid';
import { onMounted, ref, watch } from 'vue';

const props = defineProps<{
  modelValue: string;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  tabindex?: string;
  pattern?: string;
}>();

const showPopup = ref(false);
const localValue = ref<string>('');
// const patternOne = new RegExp('([0-9]{2}[0-9]{2}[0-9]{4})');
// const patternTwo = new RegExp('([0-9]{2}-{1}[0-9]{2}-{1}[0-9]{4})');
const inputEl = ref<{ inputRef: HTMLElement, validate: Function } | null>(null);

const emit = defineEmits<{
  (e: 'select', payload: string): void;
  (e: "update:modelValue", payload: string): void;
  (e: "keyup", payload: KeyboardEvent): void;
}>();

onMounted(() => {
  localValue.value === props.modelValue;
});

watch(() => props.modelValue, (newVal) => {
  localValue.value = newVal;
});

watch(() => localValue.value, (newVal) => {
  if (testValue(newVal) || newVal === '') {
    emit('update:modelValue', newVal);
    emit('select', newVal)
  }
})

const testValue = (value: string) => {
  //return (patternOne.test(value) || patternTwo.test(value))
  if (props.pattern) {
    return new RegExp(props.pattern).test(value)
  }
  return false
}

const dateSelect = (e: string) => {
  emit('select', e);
  showPopup.value = false;
  inputEl.value?.inputRef.focus();
}

defineExpose({
  inputEl
});
</script>

<template>
  <div class="relative">
    <Input
      v-model="localValue"
      :placeholder="placeholder"
      :id="id"
      :disabled="disabled"
      @keyup="(e: KeyboardEvent) => emit('keyup', e)"
      ref="inputEl"
      :pattern="pattern"
      message="Indtast venligst en gyldig værdi. DDMMÅÅÅÅ eller DD-MM-ÅÅÅÅ"
      show-reset
      @reset="() => localValue = ''"
      :tabindex="tabindex"
      input-class="pr-10"
    ></Input> <!-- ([0-9]{2}-{1}[0-9]{2}-{1}[0-9]{4})|([0-9]{2}[0-9]{2}[0-9]{4}) :min-length="8" :max-length="10"-->
    <TextButton
      color="primary"
      id="date-to-button"
      class="absolute right-1.5 top-1.5"
      @click="showPopup = true"
      tabindex="-1"
    >
      <CalendarIcon class="w-6" />
    </TextButton>
  </div>
  <PopUp
    parent-id="date-to-button"
    v-model:show="showPopup"
    class="p-2 rounded-xl"
  >
    <Calendar
      :selected="testValue(localValue) ? localValue : ''"
      show-next
      show-prev
      @date-select="(e: string) => dateSelect(e)"
    />
  </PopUp>
</template>./InputComp.vue./CalendarComp.vue